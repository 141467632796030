import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Add, AddCircle, Delete, Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import EmptySection from 'components/emptySection/emptySection';
import GozioListItem from 'components/listItem/gozioListItem';
import Asterisk from 'components/asterisk/asterisk';
import ColorOptions from 'components/colorOptions/colorOptions';
import TabPanel from 'components/tabPanel/tabPanel';
import TextField from 'components/textField/textField';
import { getFormLabel } from 'helpers/form-util';
import { USER_ROLES } from 'helpers/permissions-util';
import { useCheckRole } from 'hooks/useCheckGozioAdmin';
import { TextField as FinalTextField } from 'mui-rff';
import { buildDashboardEditStyles } from 'pages/mobileSettings/dashboard/containers/editCard/dashboardEditStyles';
import { useFindNetworkVisibilityRule } from 'hooks/dataHooks/useNetworkVisibilityRule';
import React, { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { capitalize, getLabelByLang } from 'helpers/lang-util';
import InfoTooltip from 'components/infoTooltip/infoTooltip';
import GozioSelect from 'components/selector/selector';
import ColorPalette from 'pages/gozio_colors';
import Condition from 'components/forms/condition';
import CycleCardStepperModal from './cycleCardStepperModal';
import { buildDashboardCardInitializeValues } from '../../../../../components/dashboard/dashboardHelper';
import GozioRadioGroup from '../../../../../components/radioGroup/radioGroup';

const CycleCardGroupTabPanel = ({
                                  form,
                                  formValues,
                                  onChange: onFormChange,
                                  handleGroupCardSaved,
                                  onDeleteCard,
                                  setDashboardRefreshRequired,
                                  languages = [],
                                }) => {
  const theme = useTheme();
  const styles = buildDashboardEditStyles({ theme });

  const MAX_CYCLE_CARDS = 6;

  const cardsLength = formValues?.cards?.length || 0;

  const isUberAdmin = useCheckRole() === USER_ROLES.UBER_ADMIN;
  const { data: visibilityRules } = useFindNetworkVisibilityRule();

  const [selectedCycleCard, setSelectedCycleCard] = useState(null);

  const handleUpdateOptions = (updatedListOptions) => {
    const sortedCards = [];
    updatedListOptions.forEach((opt, index) => {
      const card = formValues.cards.find(
        (card) => card.id === opt.id,
      );
      sortedCards.push({
        ...card,
        order: index,
      });
    });
    form.change('cards', sortedCards);
    onFormChange();
  };

  const showButtonOptions = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const visibilityRuleField = useMemo(() => {
    const visibilityRuleOptions = isUberAdmin ? [
      {
        id: 'allUsers',
        label: 'All Users',
      },
      {
        id: 'custom',
        label: 'Custom',
      },
    ] : [
      {
        id: 'allUsers',
        label: 'All Users',
      },
    ];
    return (
      <Field name="visibilityRuleSelect">
        {({ input }) => (
          <GozioSelect
            input={{ value: input.value, onChange: input.onChange }}
            label="Visibility Rule"
            options={
              visibilityRules
                ? visibilityRuleOptions.concat(visibilityRules.map((rule) => ({
                  id: rule.id,
                  label: capitalize(rule.name),
                })))
                : visibilityRuleOptions
            }
          />
        )}
      </Field>
    );
  }, [
    isUberAdmin,
    visibilityRules,
  ]);

  return (
    <TabPanel sx={{ ...styles.tabPanel, padding: '24px 24px 0' }} value={0} index={0}>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Field name="groupTitleEn">
            {({ input, meta }) => (
              <TextField
                label={
                  <span>
                    <Asterisk/> Card Group Name
                  </span>
                }
                charLimit={40}
                helperText={meta.touched ? meta.error : ''}
                error={meta.touched && !!meta.error}
                placeholder="Card Group Name"
                value={input.value}
                onChange={input.onChange}
                fullWidth
              />
            )}
          </Field>
          <Condition when="langES" is={true}>
            <Grid item xs={12} marginTop={'16px'}>
              <Field name="groupTitleEs">
                {({ input, meta }) => (
                  <TextField
                    label={<span>Card Group Name (Spanish)</span>}
                    charLimit={40}
                    helperText={meta.touched ? meta.error : ''}
                    error={meta.touched && !!meta.error}
                    placeholder="Card Group Name"
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </Grid>
          </Condition>
        </Grid>
      </Box>
      <Box sx={styles.fieldSection}>
        <Grid item xs={12}>
          <Typography sx={styles.displayHeading} variant="subtitle1">
            Display Options
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.displayHeading} variant="subtitle2">
            Include buttons in the cards?
          </Typography>
          <Field name="showButtons">
            {({ input }) => (
              <GozioRadioGroup
                value={input.value}
                onChange={
                  (selectedOption) => {
                    if (selectedOption === 'true') {
                      input.onChange(true);
                    } else {
                      input.onChange(false);
                    }
                  }
                }
                alignment="horizontal"
                options={showButtonOptions}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} marginTop={'16px'}>
          <Grid item xs={12}>
            <Typography sx={styles.displayHeading} variant="subtitle2">
              Text Color
            </Typography>
          </Grid>
          <Grid item marginTop={'16px'}>
            <Field name="textColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Text color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardTextColor',
                  }}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} marginTop={'16px'}>
            <Typography sx={styles.displayHeading} variant="subtitle2">
              Background Color
            </Typography>
          </Grid>
          <Grid item marginTop={'16px'}>
            <Field name="backgroundColor">
              {({ input: { value, onChange } }) => (
                <ColorOptions
                  initialColor={value}
                  formControl={true}
                  getColor={(color) => {
                    onChange(color);
                  }}
                  label="Background color"
                  required={true}
                  inputProps={{
                    'data-test': 'editCardBackgroundColor',
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        {(<Grid item>
          <Grid container>
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: '12px', marginTop: '24px', color: 'text.primary' }}
              >
                Select Visibility Rule
              </Typography>
            </Grid>
            {!isUberAdmin && (<Grid item>
              <InfoTooltip
                sx={{ marginLeft: 1 }}
                title={'This functionality is currently unavailable. Please contact Gozio support to enable this feature.'}
              />
            </Grid>)}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {visibilityRuleField}
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 32 }}>
            <Condition when="visibilityRuleSelect" is="custom">
              <Grid item xs={12}>
                <FinalTextField
                  label={getFormLabel('JSON Input')}
                  multiline
                  name="visibilityRuleCustom"
                  variant="outlined"
                  minRows={7}
                  maxRows={7}
                />
              </Grid>
            </Condition>
          </Grid>
        </Grid>)}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '14px',
            paddingTop: '5px',
          }}
        >
          <Typography variant="subtitle1" className="required">
            {cardsLength} Cycle Cards
          </Typography>
          {cardsLength > 0 && cardsLength < MAX_CYCLE_CARDS && (
            <IconButton
              onClick={() => {
                const newCycleCard = {
                  cardGroupId: formValues.cardGroupId,
                  cardGroupType: formValues.cardGroupType,
                };
                setSelectedCycleCard(newCycleCard);
              }}
              aria-label="add-tile"
              sx={{ padding: 0 }}
              size="large"
            >
              <AddCircle
                sx={{
                  color: ColorPalette.primary.main,
                  fontSize: '40px',
                }}
              />
            </IconButton>
          )}
        </Box>
        <Grid>
          {cardsLength === 0 ? (
            <EmptySection
              title="There Are No Cycle Cards Set Up"
              buttonLabel="Add a Cycle Card"
              hasPermission={true}
              ButtonIcon={Add}
              buttonClicked={() => {
                const newCycleCard = {
                  cardGroupId: formValues.cardGroupId,
                  cardGroupType: formValues.cardGroupType,
                };
                setSelectedCycleCard(newCycleCard);
              }}
            />
          ) : (
            <GozioListItem
              listHeaderName="Card Title"
              progressHeaderName="Progress"
              enableReorder={true}
              options={formValues.cards.map((card, index) => ({
                id: card.id,
                order: index,
                label: getLabelByLang(card.title),
                validation: card.validation,
                actions: [
                  {
                    Icon: Delete,
                    hover: 'Delete',
                    onClick: (cardId) => {
                      if (cardId) {
                        onDeleteCard(cardId, form);
                        setDashboardRefreshRequired(true);
                      }
                    },
                  },
                  {
                    Icon: Edit,
                    hover: 'Edit',
                    onClick: (cardId) => {
                      const selectedCard = formValues.cards.find((item) => item.id === cardId);
                      if (selectedCard) {
                        const initializedCard = buildDashboardCardInitializeValues(selectedCard);
                        initializedCard.cardId = selectedCard.id;
                        initializedCard.cardGroupType = formValues.cardGroupType;
                        initializedCard.cardGroupId = formValues.cardGroupId;
                        setSelectedCycleCard(initializedCard);
                      }
                    },
                  },
                ],
              }))}
              updateOptions={handleUpdateOptions}
            />
          )}
        </Grid>
      </Box>
      {selectedCycleCard && (<CycleCardStepperModal
          open={true}
          showButtons={formValues.showButtons}
          languages={languages}
          currentValues={selectedCycleCard}
          onSave={
            (values) => {
              handleGroupCardSaved(values, form);
              setDashboardRefreshRequired(true);
              setSelectedCycleCard(null);
            }
          }
          onClose={() => setSelectedCycleCard(null)}
        />
      )}
    </TabPanel>
  );
};

export default CycleCardGroupTabPanel;
