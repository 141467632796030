import { AddPhotoAlternate, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptySection from 'components/emptySection/emptySection';
import { arrayMove } from 'helpers/array-util';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from '../../helpers/lang-util';

const buildStyles = ({ theme }) => ({
  root: {
    backgroundColor: theme.palette.white,
    '& div.actionsHide': {
      visibility: 'hidden',
    },
    '& .MuiListItem-root': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  header: {
    display: 'inline-block',
    height: theme.spacing(2),
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  listHeader: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '440px',
  },
  listHeaderGutter: {
    borderRight: `solid 2px ${theme.palette.grey[300]}`,
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 0,
  },
  actionHeader: {},
  progressHeader: {
    borderRight: `solid 2px ${theme.palette.grey[300]}`,
    marginRight: theme.spacing(2),
  },
  headerBorder: {
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
  },
  listTitle: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  listItemOverride: {
    paddingRight: theme.spacing(2),
    minHeight: '75px',
  },
  listItemHover: {
    '&:hover': {
      background: `linear-gradient(${theme.palette.gradient.table.start}, ${theme.palette.gradient.table.end})`,
      backgroundColor: theme.palette.white,
      fontWeight: 'bold',
      '& p': {
        color: theme.palette.grey[600],
      },
      '& p:first-child': {
        fontWeight: 700,
      },
      '& div.actionsHide': {
        visibility: 'visible',
      },
    },
  },
  labelIcon: {
    marginBottom: theme.spacing(0.5),
    width: '38px',
    height: '38px',
  },
  defaultIcon: {
    alignItems: 'center',
    background: theme.palette.grey[350],
    display: 'flex',
    height: '38px',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '38px',

    '& svg': {
      color: theme.palette.white,
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  },
  primaryLine: {
    marginBottom: '2px',
  },
  actionIcon: {
    color: theme.palette.grey[600],
    fontSize: '24px',
  },
  arrowButton: {
    border: '0 !important',
    color: theme.palette.grey[600],
    height: '27px',
    padding: 0,
    minWidth: '20px',

    '& span.MuiIconButton-label': {
      justifyContent: 'flex-start',
      '& svg': { fontSize: '24px' },
    },
  },
});

const generateSecondary = (secondary, styles) => {
  if (!secondary || secondary.length === 0) return null;
  return secondary.map((line, i) => (
    <Typography
      variant="body2"
      key={`list-secondary-${line}-${i}`}
      sx={styles.secondaryLine}
    >
      {line}
    </Typography>
  ));
};

// The options array contains these look like this
// {
// 	id   - id of row
// 	order - order so we can reorder
// 	label: - first line
//  labelSx: - custom style for the label
// 	secondaryText: - secondary lines.  Can have as many as we need  ['Line 2', 'Line 3']
// 	actions: [ // if we want actions in right column
// 		{
// 			Icon: DeleteIcon,  // icon to show
// 			hover: 'Delete', // hover over icon
// 			onClick: onDelete, // function to call on click
// 		},
// 	],
// }

const GozioListItem = ({
                         listHeaderName,
                         progressHeaderName,
                         actionHeaderName,
                         options,
                         title,
                         enableReorder,
                         updateOptions,
                         emptyMessage,
                         canHover,
                         showTooltip,
                         labelSx,
                         sx,
                       }) => {
  const theme = useTheme();
  const styles = buildStyles({ theme });

  const sortedOptions = options.sort((a, b) => a.order - b.order);
  const orderUp = (idx) => {
    if (idx === 0 || !updateOptions) return;
    const newOptions = arrayMove(sortedOptions, idx, idx - 1);
    updateOptions(
      newOptions.map((opt, i) => ({
        ...opt,
        secondaryText: opt.secondaryText ? [...opt.secondaryText] : [],
        actions: [...opt.actions],
        order: i + 1,
      })),
      idx,
      idx - 1,
    );
  };
  const orderDown = (idx) => {
    if (idx >= options.length - 1 || !updateOptions) return;
    const newOptions = arrayMove(sortedOptions, idx, idx + 1);
    updateOptions(
      newOptions.map((opt, i) => ({
        ...opt,
        secondaryText: opt.secondaryText ? [...opt.secondaryText] : [],
        actions: [...opt.actions],
        order: i + 1,
      })),
      idx,
      idx + 1,
    );
  };

  return (
    <Box sx={{ ...styles.root, ...sx }}>
      {title && (
        <Typography variant="subtitle1" sx={styles.listTitle}>
          {title}
        </Typography>
      )}
      {sortedOptions.length === 0 ? (
        <EmptySection title={emptyMessage} />
      ) : (
        <>
          {!!listHeaderName && (
            <Box sx={{ display: 'flex' }}>
              {enableReorder && <Box sx={{ width: '36px' }}></Box>}
              <Box sx={{ ...styles.header, ...styles.listHeader, flex: 4 }}>
                {listHeaderName}
              </Box>
              <Box sx={styles.listHeaderGutter}></Box>
              {!!progressHeaderName && (
                <Box
                  sx={{ ...styles.header, ...styles.progressHeader, flex: 2 }}
                >
                  {progressHeaderName}
                </Box>
              )}
              <Box sx={{ ...styles.header, ...styles.actionHeader, flex: 1 }}>
                {actionHeaderName}
              </Box>
            </Box>
          )}
          <List component="nav" disablePadding>
            {sortedOptions.map(
              (
                {
                  id,
                  iconUrl,
                  label,
                  validation,
                  optionLabelSx = {},
                  iconSx = {},
                  secondaryText,
                  showDefaultIcon,
                  actions,
                },
                i,
              ) => (
                <Box sx={{ display: 'flex' }} key={`list-item-${i}`}>
                  <ListItem
                    disableGutters
                    sx={{
                      ...styles.listItemOverride,
                      ...canHover && styles.listItemHover,
                      ...!!listHeaderName && i === 0 && styles.headerBorder,
                    }}
                  >
                    {enableReorder && (
                      <Box sx={{ width: '36px' }}>
                        <Box className="actionsHide">
                          <ButtonGroup orientation="vertical">
                            <Button
                              onClick={() => orderUp(i)}
                              disabled={i === 0}
                              sx={{
                                ...styles.arrowButton,
                                marginBottom: '6px',
                              }}
                            >
                              <ArrowUpward />
                            </Button>
                            <Button
                              onClick={() => orderDown(i)}
                              sx={styles.arrowButton}
                              disabled={i >= options.length - 1}
                            >
                              <ArrowDownward />
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                    )}
                    {iconUrl && (
                      <Box sx={{ width: '48px' }}>
                        <ListItemIcon>
                          <img
                            src={iconUrl}
                            alt="Icon"
                            style={{ ...styles.labelIcon, ...iconSx }}
                          />
                        </ListItemIcon>
                      </Box>
                    )}
                    {!iconUrl && showDefaultIcon && (
                      <Box sx={{ width: '48px' }}>
                        <ListItemIcon>
                          <Box sx={{ ...styles.defaultIcon, ...iconSx }}>
                            <AddPhotoAlternate />
                          </Box>
                        </ListItemIcon>
                      </Box>
                    )}
                    <Tooltip
                      title={showTooltip && label.length > 120 ? label : ''}
                    >
                      <Box sx={{ flex: 4 }}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              sx={{ ...styles.primaryLine, ...optionLabelSx, ...labelSx }}
                            >
                              {label}
                            </Typography>
                          }
                          secondary={generateSecondary(secondaryText, styles)}
                        />
                      </Box>
                    </Tooltip>
                    {progressHeaderName && (
                      <Box sx={{ flex: 2 }}>
                        <ListItemText disableTypography>
                          <Typography variant="body1">
                            {capitalize(validation?.status)}
                          </Typography>
                        </ListItemText>
                      </Box>
                    )}
                    {actions && actions.length > 0 && (
                      <Box
                        className="actionsHide"
                        sx={{ width: '72px', paddingLeft: '24px' }}
                      >
                        {actions.map(
                          (
                            { hover, Icon, onClick = () => {} },
                            actionIndex,
                          ) => (
                            <ListItemIcon
                              key={`list-icon-action-${i}-${actionIndex}`}
                              sx={{ marginLeft: 0, marginRight: 0 }}
                            >
                              <Tooltip title={hover}>
                                <Icon
                                  sx={styles.actionIcon}
                                  onClick={() => onClick(id, i)}
                                />
                              </Tooltip>
                            </ListItemIcon>
                          ),
                        )}
                      </Box>
                    )}
                  </ListItem>
                </Box>
              ),
            )}
          </List>
        </>
      )}
    </Box>
  );
};

GozioListItem.propTypes = {
  listHeaderName: PropTypes.string,
  progressHeaderName: PropTypes.string,
  actionHeaderName: PropTypes.string,
  emptyMessage: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  enableReorder: PropTypes.bool,
  showTooltip: PropTypes.bool,
  canHover: PropTypes.bool,
  updateOptions: PropTypes.func,
  labelSx: PropTypes.object,
  sx: PropTypes.object,
};

GozioListItem.defaultProps = {
  listHeaderName: null,
  progressHeaderName: null,
  actionHeaderName: 'Actions',
  options: [],
  enableReorder: true,
  canHover: true,
  showTooltip: true,
  labelSx: {},
  sx: {},
};

export default React.memo(GozioListItem);
