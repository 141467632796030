import { Tab } from '@mui/material';
import { A2C_DISPLAY_TYPES, CARD_GROUP_TYPES, getLanguageLabel } from 'components/dashboard/cardLayouts/cardhelper';
import { buildMyFavoritesInitialValues, buildMyFavoritesSchema } from 'components/dashboard/myFavoritesHelper';
import { buildOpenPlatformInitialValues, buildOpenPlatformSchema } from 'components/dashboard/openPlatformHelper';
import { getExternalAppFormValidationSchema, getWebsiteValidationSchema } from 'helpers/form-util';
import { buildLanguageSchema, hasSpanish, LANGUAGE_CODES } from 'helpers/lang-util';
import { tabA11yProps } from 'helpers/page-util';
import _ from 'lodash';
import { uploadImage } from 'modules/media';
import {
  AccessToCareGeneralTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/accessToCareGeneralTabPanel';
import {
  AccessToCareImageDetailsTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/accessToCareImageDetailsTabPanel';
import {
  AccessToCareMapDetailsTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/accessToCareMapDetailsTabPanel';
import { DisplayTabPanel } from 'pages/mobileSettings/dashboard/containers/editCard/displayTabPanel';
import {
  buildCardDataForUpdate,
  getCategoryType,
  I18N_FIELDS,
  isValidDate,
  isValidJSON,
} from 'pages/mobileSettings/dashboard/containers/editCard/editCardHelper';
import { CARD_GROUP_I18N_FIELDS } from 'pages/mobileSettings/dashboard/containers/editCard/editCardGroupHelper';
import CycleCardGroupTabPanel from 'pages/mobileSettings/dashboard/containers/editCard/cycleCardGroupTabPanel';
import { GeneralTabPanel } from 'pages/mobileSettings/dashboard/containers/editCard/generalTabPanel';
import { LinkTabPanel } from 'pages/mobileSettings/dashboard/containers/editCard/linkTabPanel';
import { MyChartLoginTabPanel } from 'pages/mobileSettings/dashboard/containers/editCard/myChartLoginTabPanel';
import {
  MyChartQuickLinksTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/myChartQuickLinksTabPanel';
import {
  MyFavoritesActiveStateTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/myFavoritesActiveStateTabPanel';
import {
  MyFavoritesEmptyStateTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/myFavoritesEmptyStateTabPanel';
import OpenPlatformTabPanel from 'pages/mobileSettings/dashboard/containers/editCard/openPlatformTabPanel';
import {
  QuicklinkListGeneralTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/quicklinkListGeneralTabPanel';
import {
  QuicklinkListQuicklinksTabPanel,
} from 'pages/mobileSettings/dashboard/containers/editCard/quicklinkListQuicklinksTabPanel';
import React from 'react';
import * as Yup from 'yup';
import {
  buildCardGroupDataForUpdate,
} from '../../pages/mobileSettings/dashboard/containers/editCard/editCardGroupHelper';
import { ItemTypes } from '../../helpers/dnd-util';
import HeaderUnderlayLayout from './headerUnderlayLayout';
import HeaderUnderlayTabPanel from '../../pages/mobileSettings/dashboard/containers/editCard/headerUnderlayTabPanel';

export const AVAILABLE_BACKGROUND_OPTIONS = [
  {
    type: ItemTypes.DASHBOARD_OPTION,
    label: 'Header Underlay',
    layout: HeaderUnderlayLayout,
    feature: 'networkDashboardUnderlay',
  },
];

export const buildDashboardCardSchema = (cardGroupType, languages) => {
  switch (cardGroupType) {
    case CARD_GROUP_TYPES.MY_FAVORITES:
      return buildMyFavoritesSchema(languages);
    case CARD_GROUP_TYPES.OPEN_PLATFORM:
      return buildOpenPlatformSchema(languages);
    default:
      return buildGenericDashboardCardSchema(cardGroupType, languages);
  }
};

export const buildDashboardCardGroupSchema = (cardGroupType, languages) => {
  if (cardGroupType === CARD_GROUP_TYPES.CYCLE) {
    return buildGenericDashboardCardGroupSchema(cardGroupType, languages);
  }
};

export const buildGenericDashboardCardGroupSchema = (cardGroupType, languages) => Yup.object().shape(
  buildLanguageSchema(
    {
      type: Yup.string(),
      groupTitleEn: Yup.string()
        .required('Card Group Name is required')
        .max(40, 'Card Group Name must be 40 characters or less'),
      groupTitleEs: Yup.string().max(
        40,
        'Card Group Title (Spanish) must be 40 characters or less',
      ),
      showButtons: Yup.bool().required(),
      textColor: Yup.string()
        .required()
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      backgroundColor: Yup.string()
        .required()
        .matches(/^#([a-f0-9]){6}$/i, {
          message: 'Background color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
      visibilityRuleCustom: Yup.string().nullable().when('visibilityRuleSelect', {
        is: 'custom',
        then: (schema) => schema
          .test('isJSON', 'Invalid JSON input', (value) => {
            if (!value) return true;
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          })
          .required('Visibility Rule is required'),
      }),
    },
    languages,
    {
      groupTitleEn: LANGUAGE_CODES.ENGLISH,
      groupTitleEs: LANGUAGE_CODES.SPANISH,
    },
  ),
);

export const buildGenericDashboardCardSchema = (cardGroupType, languages) => Yup.object().shape(
    buildLanguageSchema(
      {
        backgroundColor: Yup.string().matches(/^#([a-f0-9]){6}$/i, {
          message: 'Background color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
        backgroundImageUrl: Yup.string().nullable(),
        cardGroupType: Yup.string(),
        categoryType: Yup.string(),
        dismissable: Yup.bool(),
        measurementHeight: Yup.string(),
        overlineEn: Yup.string().max(
          40,
          'Overline must be 40 characters or less',
        ),
        overlineEs: Yup.string().max(
          40,
          'Overline must be 40 characters or less',
        ),
        subtitleEn: Yup.string().max(
          40,
          'Subtitle must be 40 characters or less',
        ),
        subtitleEs: Yup.string().max(
          40,
          'Subtitle must be 40 characters or less',
        ),
        textAlignment: Yup.string(),
        textColor: Yup.string().matches(/^#([a-f0-9]){6}$/i, {
          message: 'Text color must match this format: #RRGGBB',
          excludeEmptyString: true,
        }),
        titleEn: Yup.string()
          .max(
            cardGroupType === 'linkListUnlimited' ? 90 : 40,
            'Title must be 40 characters or less',
          )
          .required('Title is required'),
        titleEs: Yup.string().max(
          cardGroupType === 'linkListUnlimited' ? 90 : 40,
          'Title must be 40 characters or less',
        ),
        customLogicTitleEn: Yup.string().when('sortingOptionsType', {
          is: 'rank',
          then: (schema) => schema.required('Custom logic title is required'),
        }),
        waittimeEn: Yup.string().when('waitTimesIncluded', {
          is: true,
          then: (schema) => schema.max(10, 'Wait time label must be 10 characters or less'),
        }),
        waittimeEs: Yup.string().when(['langES', 'waitTimesIncluded'], {
          is: (langES, waitTimesIncluded) => langES && waitTimesIncluded,
          then: (schema) => schema.max(10, 'Wait time label must be 10 characters or less'),
        }),

        // Quicklink Validation
        linkType: Yup.string(),
        // in_app
        inAppDest: Yup.string().when('linkType', {
          is: 'in_app',
          then: (schema) => schema.nullable(),
        }),
        // list
        listDest: Yup.string().when('linkType', {
          is: 'list',
          then: (schema) => schema.nullable(),
        }),
        // External App
        ...getExternalAppFormValidationSchema,
        // Website
        ...getWebsiteValidationSchema,
        // Custom
        custom: Yup.string().when('linkType', {
          is: 'custom',
          then: (schema) => schema.nullable().required('This open field is required'),
        }),

        // MyChart
        qlTitleEn: Yup.string().when('cardGroupType', {
          is: 'myChart',
          then: (schema) => schema
              .max(40, 'Title must be 40 characters or less')
              .required('Title is required'),
        }),
        qlTitleEs: Yup.string().max(40, 'Title must be 40 characters or less'),
        qlBackgroundColor: Yup.string().when('cardGroupType', {
          is: 'myChart',
          then: (schema) => schema.matches(/^#([a-f0-9]){6}$/i, {
              message: 'Background color must match this format: #RRGGBB',
              excludeEmptyString: true,
            }),
        }),
        qlTextColor: Yup.string().when('cardGroupType', {
          is: 'myChart',
          then: (schema) => schema.matches(/^#([a-f0-9]){6}$/i, {
              message: 'Text color must match this format: #RRGGBB',
              excludeEmptyString: true,
            }),
        }),
        qlQuickLinks: Yup.array(),
        qlVisibilityRule: Yup.string().when('cardGroupType', {
          is: 'myChart',
          then: (schema) => schema.nullable().test('isJSON', 'Invalid JSON input', isValidJSON),
        }),
        myChartUrl: Yup.string(),
        visibilityRule: Yup.string()
          .nullable()
          .test('isJSON', 'Invalid JSON input', isValidJSON),
      },
      languages,
      {
        overlineEn: LANGUAGE_CODES.ENGLISH,
        overlineEs: LANGUAGE_CODES.SPANISH,
        subtitleEn: LANGUAGE_CODES.ENGLISH,
        subtitleEs: LANGUAGE_CODES.SPANISH,
        titleEn: LANGUAGE_CODES.ENGLISH,
        titleEs: LANGUAGE_CODES.SPANISH,
        waittimeEn: LANGUAGE_CODES.ENGLISH,
        waittimeEs: LANGUAGE_CODES.SPANISH,
        qlTitleEn: LANGUAGE_CODES.ENGLISH,
        qlTitleEs: LANGUAGE_CODES.SPANISH,
      },
    ),
  );

export const buildBackgroundOptionSchema = () => Yup.object().shape({
    underlayHeaderColor: Yup.string()
      .required('Underlay header color is required')
      .matches(/^#([a-f0-9]){6}$/i, {
        message: 'Underlay header color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
    underlayTextColor: Yup.string()
      .required('Underlay text color is required')
      .matches(/^#([a-f0-9]){6}$/i, {
        message: 'Underlay text color must match this format: #RRGGBB',
        excludeEmptyString: true,
      }),
    numberOfCards: Yup.number()
      .required('Number of cards is required')
      .min(0, 'Number of Cards must be a positive number'),
  },
);

export const submitDashboardCard = async ({
                                            cardGroupType,
                                            cardGroupId,
                                            cardId,
                                            customLocations,
                                            dismissable,
                                            expires,
                                            hasWaitTimes,
                                            initialValues,
                                            languages,
                                            onEditCardSaved,
                                            quickLinks,
                                            setInitialValues,
                                            setUploadedIcon,
                                            setUploadedBackgroundImageFile,
                                            size,
                                            toastNotificationErrorHook,
                                            updateCard,
                                            updateMyChartCard,
                                            uploadedBackgroundImageFile,
                                            uploadedIcon,
                                            useColorFillAsBackground,
                                            values,
                                            waitTimes,
                                          }) => {
  if (dismissable || !!expires) {
    if (!(values.dismissable || (values.expiresFlag && !!values.expires))) {
      throw new Error(
        'Dismissable and/or Expires are required for a temporary card.',
      );
    }

    if (values.expiresFlag && !isValidDate(values.expires)) {
      throw new Error('Invalid Expires on date.');
    }
  }

  const uploadedImages = {};
  if (uploadedIcon?.file) {
    uploadedImages.iconImage = await uploadImage(uploadedIcon.file);
  }
  if (uploadedBackgroundImageFile) {
    uploadedImages.backgroundImage = await uploadImage(
      uploadedBackgroundImageFile.file,
    );
  }

  setUploadedIcon(null);
  setUploadedBackgroundImageFile(null);

  const updatedValues = { ...values };
  if (values.qlQuickLinks) {
    updatedValues.qlQuickLinks = await Promise.all(
      [...values.qlQuickLinks].map(async ({ iconFile, ...rest }) => {
        if (iconFile) {
          const uploadedImage = await uploadImage(
            iconFile,
            toastNotificationErrorHook,
          );
          return {
            ...rest,
            iconUrl: uploadedImage.secureUrl,
          };
        }

        return { ...rest };
      }),
    );
  }

  const { backgroundImage, iconImage } = uploadedImages;
  const updatedData = buildCardDataForUpdate({
    cardGroupType,
    cardGroupId,
    cardId,
    backgroundImage,
    customLocations,
    hasWaitTimes,
    iconImage,
    languages,
    quickLinks,
    size,
    useColorFillAsBackground,
    values: updatedValues,
    waitTimes,
    initialValues,
  });

  if (CARD_GROUP_TYPES.CARE_NEAR_ME === cardGroupType) {
    ['iconUrl', 'iconUrl2', 'backgroundImageUrl'].forEach((field) => {
      if (updatedData[field] !== initialValues[field]) {
        setInitialValues(
          Object.assign(initialValues, { [field]: updatedData[field] }),
        );
      }
    });
  }

  if (cardGroupType === CARD_GROUP_TYPES.MYCHART) {
    try {
      await updateMyChartCard({ variables: updatedData });
      await onEditCardSaved();
      setInitialValues(null);
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  } else {
    try {
      const id = updatedData.cardId;
      delete updatedData.cardId;
      delete updatedData.cardGroupId;
      if (updatedData.backgroundImageUrl === '') {
        updatedData.backgroundImageUrl = null;
      }
      await updateCard({ variables: { id, input: updatedData } });
      await onEditCardSaved();
      setInitialValues(null);
    } catch (err) {
      toastNotificationErrorHook(err.message);
    }
  }
};

export const submitDashboardCardGroup = async ({
                                                 cardGroupId,
                                                 initialValues,
                                                 values,
                                                 updateCardGroup,
                                                 onEditCardGroupSaved,
                                                 setInitialValues,
                                                 toastNotificationErrorHook,
                                               }) => {
  try {
    const updatedCardGroupData = buildCardGroupDataForUpdate({
      initialValues,
      values,
    });

    await updateCardGroup({ variables: { cardGroupId, input: updatedCardGroupData } });
    await onEditCardGroupSaved();

    setInitialValues(null);
  } catch (err) {
    toastNotificationErrorHook(err.message);
  }
};

const mapLinkData = (linkData, languages = []) => {
  if (!linkData) {
    return {};
  }

  const actionLink = {
    linkType: linkData.type,
  };

  switch (linkData.type) {
    case 'category':
      actionLink.categories = linkData.categories;
      break;
    case 'in_app':
      actionLink.inAppDest = linkData.data.id || '';
      break;
    case 'list':
      actionLink.listDest = linkData.data.id || '';
      break;
    case 'external_app':
      actionLink.externalURL = linkData.data.url;
      actionLink.externalAndroidURL = linkData.data.androidUrl;
      actionLink.externaliOSURL = linkData.data.iosUrl;
      actionLink.externalType = linkData.data.client;
      actionLink.fallbackAndroidURL = linkData.data.androidFallbackUrl;
      actionLink.fallbackiOSURL = linkData.data.iosFallbackUrl;
      break;
    case 'website':
      actionLink.webUrlEn = linkData.data.url?.en;
      if (hasSpanish(languages)) {
        actionLink.webUrlEs = linkData.data.url?.es;
      }
      actionLink.webType = linkData.data.client;
      break;
    case 'custom':
      actionLink.custom = linkData.data.url;
      break;
    default:
      break;
  }

  return actionLink;
};

/**
 * @param {Object} props
 * @param {string[]} props.languages
 * @param {Object} props.card1
 * @param {Object} props.card2
 * @param {string} props.cardGroupId
 * @param {string} props.cardGroupType
 * @param {string} props.cardName
 * @param {string} props.customLogicTitle
 * @param {string} props.backgroundColor
 * @param {string} props.backgroundImageUrl
 * @param {string|Object[]} props.buttonText
 * @param {boolean} props.dismissable
 * @param {string} props.displayType
 * @param {string} props.expires
 * @param {string} props.iconUrl
 * @param {string} props.iconUrl2
 * @param {string} props.linkBackgroundColor
 * @param {Object} props.linkData
 * @param {Object} props.locations
 * @param {string} props.measurementHeight
 * @param {Object} props.module
 * @param {string} props.myChartUrl
 * @param {Object[]} props.quickLinks
 * @param {string} props.textAlignment
 * @param {string} props.textColor
 * @param {Object} props.waitTimes
 * @param {string} props.sortingOptionsType
 * @param {string} props.visibilityRule
 */
export const buildDashboardCardInitializeValues = (props) => {
  const {
    languages,
    card1,
    card2,
    cardGroupId,
    cardGroupType,
    cardName,
    customLogicTitle,
    backgroundColor,
    backgroundImageUrl,
    buttonText,
    dismissable,
    displayType,
    expires,
    iconUrl,
    iconUrl2,
    linkBackgroundColor,
    linkData,
    locations = {},
    measurementHeight,
    module,
    myChartUrl,
    quickLinks,
    textAlignment,
    textColor,
    waitTimes,
    sortingOptionsType,
    visibilityRule,
  } = props;
  if (cardGroupType === CARD_GROUP_TYPES.MY_FAVORITES) {
    return buildMyFavoritesInitialValues(
      { cardGroupId, cardGroupType, card1, card2 },
      languages,
    );
  }

  if (cardGroupType === CARD_GROUP_TYPES.OPEN_PLATFORM) {
    return buildOpenPlatformInitialValues({
      cardGroupId,
      cardGroupType,
      cardName,
      module,
      visibilityRule,
    });
  }

  const actionLink = mapLinkData(linkData, languages);

  const map = {
    langES: hasSpanish(languages),
    backgroundColor,
    backgroundImageUrl,
    cardGroupType,
    categoryType: getCategoryType(locations),
    ...cardGroupType === CARD_GROUP_TYPES.TEMPORARY && {
      dismissable,
      expiresFlag: !!expires,
      expires: expires ? new Date(expires) : '',
    },
    iconUrl,
    iconUrl2,
    measurementHeight,
    myChartUrl: myChartUrl || '',
    textAlignment,
    textColor,
    waitTimesIncluded: waitTimes?.included,
    waitTimesShowWhenCollapsed: waitTimes?.showWhenCollapsed,
    categories: [],
    ...cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME && {
      displayType: displayType || A2C_DISPLAY_TYPES.MAP,
    },
    externalURL: '',
    externalType: 'native',
    externaliOSURL: '',
    externalAndroidURL: '',
    fallbackiOSURL: '',
    fallbackAndroidURL: '',
    hasPlatformSpecificFallbacks: false,
    inAppDest: '',
    webType: 'native',
    ...cardGroupType === CARD_GROUP_TYPES.FINDING_CARE && {
      linkBackgroundColor,
    },
    ...quickLinks && {
      qlTitleEn: getLanguageLabel(quickLinks.title) || '',
      qlTitleEs:
        getLanguageLabel(quickLinks.title, LANGUAGE_CODES.SPANISH) || '',
      qlBackgroundColor: quickLinks.backgroundColor,
      qlTextColor: quickLinks.textColor,
      qlVisibilityRule: quickLinks.visibilityRule || '',
    },
    ...cardGroupType === CARD_GROUP_TYPES.MYCHART && {
      // MyChart
      qlQuickLinks:
        quickLinks?.quickLinksData?.map(
          ({ id, name, iconUrl, url }, index) => ({
            ...id && { id },
            index,
            name,
            iconUrl,
            url,
          }),
        ) || [],
    },
    ...[CARD_GROUP_TYPES.CARE_NEAR_ME].includes(cardGroupType) && {
      sortingOptionsType: sortingOptionsType || 'distance',
      customLogicTitleEn: getLanguageLabel(customLogicTitle),
      customLogicTitleEs: getLanguageLabel(
        customLogicTitle,
        LANGUAGE_CODES.SPANISH,
      ),
    },
    ...cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME && {
      buttonTextEn: getLanguageLabel(buttonText),
      buttonTextEs: getLanguageLabel(buttonText, LANGUAGE_CODES.SPANISH),
    },
    ...(cardGroupType === CARD_GROUP_TYPES.FINDING_CARE
      || cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME
      || cardGroupType === CARD_GROUP_TYPES.QUICKLINK_LIST) && {
      // Access to Care
      qlQuickLinks:
        quickLinks?.map(
          (
            {
              id,
              name,
              iconUrl,
              iconTint,
              url,
              type,
              data,
              categories,
              curatedList,
              validation,
            },
            index,
          ) => ({
            ...id && { id },
            index,
            name,
            iconUrl,
            iconTint,
            url,
            type,
            data,
            categories,
            curatedList,
            validation,
          }),
        ) || [],
    },
    visibilityRule,
    ...actionLink,
  };

  if (
    linkData?.type === 'external_app'
    && (linkData.data?.androidFallbackUrl || linkData.data?.iosFallbackUrl)
  ) {
    map.hasPlatformSpecificFallbacks = true;
  }
  I18N_FIELDS.forEach(({ key, langKey, lang }) => {
    const i18nList
      = key === 'waittime' ? _.get(waitTimes, 'label', []) : props[key];
    const langValue = getLanguageLabel(i18nList, lang);
    map[langKey] = langValue || '';
  });

  return map;
};

export const buildDashboardCardGroupInitializeValues = (props) => {
  const {
    languages,
    cardGroupId,
    cardGroupType,
    showButtons,
    textColor,
    backgroundColor,
    visibilityRule,
    visibilityRuleCustom,
    cards,
  } = props;

  const map = {
    langES: hasSpanish(languages),
    cardGroupId,
    cardGroupType,
    showButtons,
    textColor,
    backgroundColor,
    cards,
  };

  if (visibilityRuleCustom !== null) {
    map.visibilityRuleSelect = 'custom';
    map.visibilityRuleCustom = visibilityRuleCustom;
  } else if (visibilityRule !== null) {
    map.visibilityRuleSelect = visibilityRule.id;
    map.visibilityRuleCustom = null;
  } else {
    map.visibilityRuleSelect = 'allUsers';
    map.visibilityRuleCustom = null;
  }

  CARD_GROUP_I18N_FIELDS.forEach(({ key, langKey, lang }) => {
    const i18nList = props[key];
    const langValue = getLanguageLabel(i18nList, lang);
    map[langKey] = langValue || '';
  });
  return map;
};

export const buildDashboardBackgroundOptionInitializeValues = (props) => {
  const { underlayHeaderColor, underlayTextColor, numberOfCards } = props;
  return { underlayHeaderColor, underlayTextColor, numberOfCards };
};

export const renderTabHeaders = ({ cardGroupType, values }) => {
  if (cardGroupType === CARD_GROUP_TYPES.MYCHART) {
    return [
      <Tab
        key="tab_0"
        label="LOGIN CARD"
        {...tabA11yProps('dashboard-edit', 0)}
      />,
      <Tab
        key="tab_1"
        label="MYCHART QUICKLINKS CARD"
        {...tabA11yProps('dashboard-edit', 1)}
      />,
    ];
  }

  if (
    cardGroupType === CARD_GROUP_TYPES.FINDING_CARE
    || cardGroupType === CARD_GROUP_TYPES.QUICKLINK_LIST
  ) {
    return [
      <Tab
        key="tab_0"
        label="GENERAL"
        {...tabA11yProps('dashboard-edit', 0)}
      />,
      <Tab
        key="tab_1"
        label="QUICKLINKS"
        {...tabA11yProps('dashboard-edit', 1)}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME) {
    return [
      <Tab
        key="tab_0"
        label="GENERAL"
        {...tabA11yProps('dashboard-edit', 0)}
      />,
      <Tab
        key="tab_1"
        label="MAP DETAILS"
        sx={{
          ...values?.displayType === A2C_DISPLAY_TYPES.IMAGE && {
            display: 'none',
          },
        }}
        {...tabA11yProps('dashboard-edit', 1)}
      />,
      <Tab
        key="tab_2"
        label="IMAGE DETAILS"
        sx={{
          ...values?.displayType === A2C_DISPLAY_TYPES.MAP && {
            display: 'none',
          },
        }}
        {...tabA11yProps('dashboard-edit', 2)}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.MY_FAVORITES) {
    return [
      <Tab
        key="tab_0"
        label="EMPTY STATE"
        {...tabA11yProps('dashboard-edit', 0)}
      />,
      <Tab
        key="tab_1"
        label="ACTIVE STATE"
        {...tabA11yProps('dashboard-edit', 1)}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.OPEN_PLATFORM || cardGroupType === CARD_GROUP_TYPES.CYCLE) {
    return [
      <Tab
        key="tab_0"
        label="GENERAL"
        {...tabA11yProps('dashboard-edit', 0)}
      />,
    ];
  }

  return [
    <Tab key="tab_0" label="GENERAL" {...tabA11yProps('dashboard-edit', 0)} />,
    <Tab
      key="tab_1"
      label="QUICKLINK"
      {...tabA11yProps('dashboard-edit', 1)}
    />,
    <Tab key="tab_2" label="DISPLAY" {...tabA11yProps('dashboard-edit', 2)} />,
  ];
};

export const renderDashboardBackgroundOptionTabPanels = (props) => {
  const { type, cardGroups } = props;
  if (type === 'headerUnderlay') {
    return [
      <HeaderUnderlayTabPanel
        key="tabPanel-0"
        cardGroups={cardGroups}
      />,
    ];
  }
};

export const renderDashboardCardGroupTabPanels = ({
                                                    form,
                                                    handleFormChange,
                                                    handleGroupCardSaved,
                                                    onDeleteCard,
                                                    setDashboardRefreshRequired,
                                                    props,
                                                    values,
                                                  }) => {
  const { languages, cardGroupType } = props;
  if (cardGroupType === CARD_GROUP_TYPES.CYCLE) {
    return [
      <CycleCardGroupTabPanel
        key="tabPanel-0"
        form={form}
        formValues={values}
        onChange={() => handleFormChange(form)}
        handleGroupCardSaved={handleGroupCardSaved}
        onDeleteCard={onDeleteCard}
        setDashboardRefreshRequired={setDashboardRefreshRequired}
        languages={languages}
      />,
    ];
  }
};

export const renderDashboardCardTabPanels = ({
                                  sx,
                                  customLocations,
                                  form,
                                  handleIconUploaded,
                                  handleFormChange,
                                  props,
                                  setCustomLocations,
                                  setUploadedBackgroundImageFile,
                                  setUseColorFillAsBackground,
                                  tabValue,
                                  useColorFillAsBackground,
                                  values,
                                }) => {
  const {
    languages,
    cardGroupType,
    backgroundColor,
    buttonText,
    dismissable,
    expires,
    hasWaitTimes,
    linkBackgroundColor,
    locations = {},
    quickLinks,
    size,
    subtitle,
    subtitle2,
    textColor,
  } = props;

  if (cardGroupType === CARD_GROUP_TYPES.MYCHART) {
    return [
      <MyChartLoginTabPanel
        key="tabPanel-0"
        backgroundColor={backgroundColor}
        sx={sx}
        formValues={values}
        index={0}
        languages={languages}
        size={size}
        onChange={() => handleFormChange(form)}
        onIconUploaded={(icon) => handleIconUploaded(form, icon)}
        tabValue={tabValue}
        textColor={textColor}
        onBackgroundImageFileUploaded={(file) => setUploadedBackgroundImageFile(file)
        }
        setUseColorFillAsBackground={setUseColorFillAsBackground}
        useColorFillAsBackground={useColorFillAsBackground}
        form={form}
      />,
      <MyChartQuickLinksTabPanel
        key="tabPanel-1"
        qlBackgroundColor={quickLinks.backgroundColor}
        sx={sx}
        form={form}
        formValues={values}
        index={1}
        languages={languages}
        onChange={() => handleFormChange(form, {}, true)}
        size={size}
        tabValue={tabValue}
        qlTextColor={quickLinks.textColor}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.FINDING_CARE) {
    return [
      <AccessToCareGeneralTabPanel
        key="tabPanel-0"
        backgroundColor={backgroundColor}
        buttonText={buttonText}
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={0}
        languages={languages}
        size={size}
        onChange={() => handleFormChange(form)}
        onIconUploaded={(icon, fieldName) => handleIconUploaded(form, icon, fieldName)
        }
        subtitle={subtitle}
        subtitle2={subtitle2}
        tabValue={tabValue}
        textColor={textColor}
      />,
      <AccessToCareMapDetailsTabPanel
        key="tabPanel-1"
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={1}
        languages={languages}
        linkBackgroundColor={linkBackgroundColor}
        onChange={() => handleFormChange(form, {}, true)}
        size={size}
        tabValue={tabValue}
        textColor={textColor}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.CARE_NEAR_ME) {
    return [
      <AccessToCareGeneralTabPanel
        key="tabPanel-0"
        backgroundColor={backgroundColor}
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={0}
        languages={languages}
        size={size}
        onChange={() => handleFormChange(form)}
        onIconUploaded={(icon, fieldName) => handleIconUploaded(form, icon, fieldName)
        }
        tabValue={tabValue}
        textColor={textColor}
      />,
      <AccessToCareMapDetailsTabPanel
        key="tabPanel-1"
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={1}
        languages={languages}
        linkBackgroundColor={linkBackgroundColor}
        onChange={() => handleFormChange(form, {}, true)}
        size={size}
        tabValue={tabValue}
        textColor={textColor}
      />,
      <AccessToCareImageDetailsTabPanel
        key="tabPanel-2"
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={2}
        languages={languages}
        onChange={(formValues = {}) => handleFormChange(form, formValues, true)}
        onIconUploaded={(icon, fieldName) => handleIconUploaded(form, icon, fieldName)}
        size={size}
        tabValue={tabValue}
        textColor={textColor}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.QUICKLINK_LIST) {
    return [
      <QuicklinkListGeneralTabPanel
        key="tabPanel-0"
        backgroundColor={backgroundColor}
        sx={sx}
        formValues={values}
        index={0}
        languages={languages}
        tabValue={tabValue}
        textColor={textColor}
      />,
      <QuicklinkListQuicklinksTabPanel
        key="tabPanel-1"
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={1}
        languages={languages}
        onChange={() => handleFormChange(form, {}, true)}
        tabValue={tabValue}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.MY_FAVORITES) {
    return [
      <MyFavoritesEmptyStateTabPanel
        key="tabPanel-0"
        backgroundColor={props.card1.backgroundColor}
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={0}
        languages={languages}
        size={size}
        onChange={() => handleFormChange(form)}
        onIconUploaded={(icon, fieldName) => handleIconUploaded(form, icon, fieldName)
        }
        tabValue={tabValue}
        textColor={props.card1.textColor}
      />,
      <MyFavoritesActiveStateTabPanel
        key="tabPanel-1"
        backgroundColor={props.card2.backgroundColor}
        cardGroupType={cardGroupType}
        sx={sx}
        form={form}
        formValues={values}
        index={1}
        languages={languages}
        size={size}
        onChange={() => handleFormChange(form)}
        onIconUploaded={(icon, fieldName) => handleIconUploaded(form, icon, fieldName)
        }
        tabValue={tabValue}
        textColor={props.card2.textColor}
      />,
    ];
  }

  if (cardGroupType === CARD_GROUP_TYPES.OPEN_PLATFORM) {
    return [<OpenPlatformTabPanel key="tabPanel-0" />];
  }

  return [
    <GeneralTabPanel
      key="tabPanel-0"
      cardGroupType={cardGroupType}
      sx={sx}
      dismissable={dismissable}
      expires={expires}
      formValues={values}
      hasWaitTimes={hasWaitTimes}
      index={0}
      languages={languages}
      size={size}
      tabValue={tabValue}
    />,
    <LinkTabPanel
      key="tabPanel-1"
      cardGroupType={cardGroupType}
      sx={sx}
      customLocations={customLocations}
      form={form}
      formValues={values}
      index={1}
      locations={locations}
      onCustomLocationsAdded={(locations) => setCustomLocations(locations)}
      tabValue={tabValue}
    />,
    <DisplayTabPanel
      key="tabPanel-2"
      backgroundColor={backgroundColor}
      cardGroupType={cardGroupType}
      sx={sx}
      index={2}
      form={form}
      size={size}
      onBackgroundImageFileUploaded={(file) => setUploadedBackgroundImageFile(file)}
      onChange={() => handleFormChange(form)}
      onIconUploaded={(icon) => handleIconUploaded(form, icon)}
      setUseColorFillAsBackground={setUseColorFillAsBackground}
      tabValue={tabValue}
      textColor={textColor}
      useColorFillAsBackground={useColorFillAsBackground}
    />,
  ];
};
