import { LANGUAGE_CODES } from '../../../../../helpers/lang-util';
import { processUpdatedI18NData } from './editCardHelper';

export const CARD_GROUP_I18N_FIELDS = [
  {
    key: 'groupTitle',
    lang: LANGUAGE_CODES.ENGLISH,
    langKey: 'groupTitleEn',
    label: 'Card Group Title',
    charLimit: 40,
    required: true,
  },
  {
    key: 'groupTitle',
    lang: LANGUAGE_CODES.SPANISH,
    langKey: 'groupTitleEs',
    label: 'Card Group Title (Spanish)',
    charLimit: 40,
  },
];

export const getErrorFormFieldMap = () => new Map([
    [
      'groupTitleEn',
      { label: 'Group Title', section: 'Group Title' },
    ],
    [
      'groupTitleEs',
      { label: 'Group Title (Spanish)', section: 'Group Title' },
    ],
    [
      'showButtons',
      { label: 'Show Buttons', section: 'Display Options' },
    ],
    [
      'textColor',
      { label: 'Text Color', section: 'Display Options' },
    ],
    [
      'backgroundColor',
      { label: 'Background Color', section: 'Display Options' },
    ],
    [
      'visibilityRuleSelect',
      { label: 'Visibility Rule', section: 'Visibility Rule' },
    ],
    [
      'visibilityRuleCustom',
      { label: 'Custom Visibility Rule', section: 'Visibility Rule' },
    ],
  ],
);

export const buildCardGroupDataForUpdate = ({
                                              initialValues = {},
                                              values,
                                            }) => {
  const updatedData = {};
  const unprocessedValues = {};
  Object.keys(initialValues).forEach((key) => (unprocessedValues[key] = false));

  Object.keys(values).forEach((key) => {
    delete unprocessedValues[key];
    const index = key.length - 2;
    const fieldName = key.substring(0, index);
    const isI18NField = !!CARD_GROUP_I18N_FIELDS.find((f) => f.key === fieldName);
    const value = values[key];
    if (isI18NField) {
      const lang = key.substring(index).toLowerCase();
      processUpdatedI18NData({ fieldName, lang, value, updatedData });
    } else {
      switch (key) {
        case 'showButtons':
          updatedData.showButtons = value;
          break;
        case 'visibilityRuleSelect':
          if (value === 'custom') {
            updatedData.visibilityRule = null;
            updatedData.visibilityRuleCustom = values.visibilityRuleCustom;
          } else if (value === 'allUsers') {
            updatedData.visibilityRuleCustom = null;
            updatedData.visibilityRule = null;
          } else {
            updatedData.visibilityRuleCustom = null;
            updatedData.visibilityRule = values.visibilityRuleSelect;
          }
          break;
        case 'textColor':
          updatedData.textColor = value;
          break;
        case 'backgroundColor':
          updatedData.backgroundColor = value;
          break;
        case 'cards':
          updatedData.cards = value.map((card) => card.id);
          break;
        default:
          break;
      }
    }
  });

  return updatedData;
};

// Maps the edited card values to preview to display on the mobile preview.
export const mapEditCardGroupToPreview = ({
                                            cardGroupId,
                                            languages = [],
                                            selectedCardGroup,
                                            values,
                                          }) => {
  const cardGroup = {};
  CARD_GROUP_I18N_FIELDS.forEach(({ key, lang, langKey }) => {
    if (!cardGroup.hasOwnProperty(key)) {
      cardGroup[key] = [];
    }
    if (languages.includes(lang)) {
      cardGroup[key].push({ lang, label: values[langKey] || '' });
    }
  });

  for (const key in selectedCardGroup) {
    if (!cardGroup.hasOwnProperty(key)) {
      cardGroup[key] = values[key];
    }
  }

  cardGroup.textColor = values.textColor;
  cardGroup.showButtons = values.showButtons;
  cardGroup.visibilityRule = values.visibilityRule;
  cardGroup.visibilityRuleCustom = values.visibilityRuleCustom;

  return {
    cardGroupId: cardGroupId,
    cardGroupType: selectedCardGroup.type,
    cardGroup: cardGroup,
  };
};
